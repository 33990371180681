<div class="content" role="main" style="background-color: aliceblue;">
    <div class="sticky row pr-2">

        <div class="col-md-4">
            <div style="width: fit-content;">
                <h4>Service Data Viewer</h4>
                <h6 style="text-align: end;">{{this.appVersion}}</h6>
            </div>
        </div>

        <div class="search col-md-8 row pb-2">
            <span class="failed" (click)="showAlerts=true"><mat-icon [style.line-height]="2"
                    [style.font-size]="'xx-large'" style="cursor: pointer;"
                    *ngIf="Alerts.length!=0">warning</mat-icon></span>
            <mat-form-field class="example-form-field ml-4" appearance="fill">
                <mat-label>Device Number</mat-label>
                <input matInput type="text" autocomplete="off" [(ngModel)]="deviceNo"
                    (keyup.enter)="getAllServicedata(deviceID.value)" #deviceID value="">
            </mat-form-field>
            <div class="btns_wrapper">
                <div>
                    <button mat-raised-button class="ml-3 row" color="primary"
                        style="height: fit-content; margin-top: 15px;" (click)='getAllServicedata(deviceID.value)'
                        [disabled]="deviceID.value.length != 5 || isSpinner">
                        <mat-icon *ngIf="isSpinner">
                            <mat-spinner diameter="20">
                            </mat-spinner>
                        </mat-icon>&nbsp;Search
                    </button>
                    <button mat-raised-button class="ml-3 row" color="primary"
                        style="height: fit-content; margin-top: 15px;" (click)='clearTables(deviceID)'
                        [disabled]="!(this.dataSource?.data?.length>0)">
                        &nbsp;Clear</button>
                    <button mat-raised-button class="ml-3 row" color="primary"
                        style="height: fit-content; margin-top: 15px;" (click)='showGraph()'
                        [disabled]="!(this.dataSource?.data?.length>0)">
                        &nbsp;Graph</button>

                </div>
                <div class="logout">
                    <mat-icon style="font-size: 35px;cursor: pointer; margin-top: 15px;"
                        [matMenuTriggerFor]="menu">person</mat-icon>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <mat-icon>person</mat-icon>
                            <span>{{userName}}</span>
                        </button>
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>logout</mat-icon>
                            <span>Logout</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-md-7 mt-2">
            <div class="table-wrapper">
                <table mat-table #Sort="matSort" [dataSource]="dataSource" *ngIf="dataSource" matSort
                    (matSortChange)="sortbyColumn($event)">

                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef> Details </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon class="ylet-primary-500" style="cursor: pointer;"
                                (click)="displayServiceDatatable(element.id)">feed</mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="testDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.testDate.slice(5,25)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="SelfTest">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Self Test </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="element.SelfTest==0 ? 'fail' : 'pass'">
                            {{element.SelfTest==0?'Fail':'Pass'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{'highlight':selectedRowIndex == row.id}"></tr>

                </table>
            </div>
            <mat-paginator class="mat-elevation-z7 paginator" [pageSizeOptions]="[13,30,45]" [hidden]="!dataSource"
                #paginator>
            </mat-paginator>
            <div class="row" *ngIf="maintenanceReport">
                <button mat-raised-button class="ml-3 row" color="primary"
                    style="height: fit-content; margin-top: 15px;" (click)='report()' [disabled]="!notType">
                    &nbsp;Maintenance Report</button>
                <button mat-raised-button class="ml-3 row" color="primary"
                    style="height: fit-content; margin-top: 15px;" (click)='report1()' [disabled]="!notType">
                    &nbsp;Auto Service Data Report</button>
            </div>
            <span style="color: red;font-size: 12px !important;" [innerHTML]="errorMsg"></span>

            <!-- Alerts -->
            <div class="mt-2" *ngIf="showAlerts">

                <div class="card alert" *ngFor="let alert of Alerted"
                    [style.background-color]="isAdmin?'#f2dccc':'#FFF9E9'">
                    <div class="inner-alert-content">
                        <mat-icon
                            [style]="isAdmin ? 'line-height: 2.5;margin-left: 15px;font-size: 30px;': 'line-height: 2;margin-left: 15px;font-size: 30px;'"
                            [style.color]="isAdmin ? alert.color : alert.recommendationColor">info</mat-icon>
                        <div class="ml-3" style="display: flex;flex-direction:column; margin: 10px;">
                            <strong class="pt-1 danger" style="font-weight: bold;"
                                [style.color]="isAdmin ? alert.color : alert.recommendationColor">{{isAdmin ?
                                alert.title +'Failure' : ''}}
                            </strong>
                            <div *ngIf="isAdmin else recommendation">
                                <label class="pt-1" style="color: black;">The Device has
                                    <b>{{alert.percent.toFixed(1)}}%
                                        Failure{{alert.specificPercent?' in the last month':''}}</b></label>
                            </div>
                            <ng-template #recommendation>
                                <label class="pt-1" style="color: black;">
                                    <b>{{alert.recommendation}}</b></label>
                            </ng-template>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="col-md-5 mt-2">
            <div class="table-wrapper">
                <table mat-table #ServiceSort="matSort" [dataSource]="serviceData" *ngIf="serviceData" matSort
                    (matSortChange)="sortService($event)">
                    <ng-container matColumnDef="parameters">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Parameter </th>
                        <td mat-cell *matCellDef="let element"> {{element.parameters}} </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef> Value </th>
                        <td mat-cell [ngClass]="{'failed':element.notInRange.status}" *matCellDef="let element">
                            <span><mat-icon [matTooltip]="element.notInRange.hint" matTooltipPosition="left"
                                    style="vertical-align: bottom;"
                                    *ngIf="element.notInRange.status">info</mat-icon></span> {{element.value}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="calibrated">
                        <th mat-header-cell *matHeaderCellDef> Calibrated Value </th>
                        <td mat-cell *matCellDef="let element"> {{element.calibrated}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="parameterColumn"></tr>
                    <tr mat-row *matRowDef="let row; columns: parameterColumn;"></tr>
                </table>
            </div>

        </div>
    </div>

    <!----------*** No Device Found Error ***---------->
    <div class="no-device-container" *ngIf="isNoDeviceFound">
        <h4 class="no-device">No Device Found</h4>
    </div>

</div>