import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
// import ss from "";
import pdfMake from "../../assets/js/bundles/export-tables/pdfmake.min.js";
import pdfFonts from '../../assets/js/bundles/export-tables/vfs_fonts.js';
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import { GlobalService } from '../global.service';
import { titles } from '../globals/globals';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare let pdfMake: any;
@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  measurementsParams = { 'ledCurrent1': 'mA', 'ledCurrent2': 'mA', 'ref1': 'mV', 'ref2': 'mV', 'zeroLevel': 'bit', 'odCap': 'bit', 'odRef': 'bit' }
  calculationParams = {
    amplitude: 'mV',
    average: '',
    aw: '',
    noiseLevel: '',
    averageSignalStd: '',
    averageStd: '',
    count: '',
    noiseLevelStd: '',
    od: '',
    zeroLevelStdSignal: '',
  }
  settingsParams = {
    amplitudeAMP: '',
    controlZeroLevel: '',
    minSpHeight: '',
    minSpWidth: '',
    maxSpWidth: '',
    LB_OD_AMP: '',
    controlRef1: '',
    controlRef2: '',
    mscAmplification: '',
    signalFiltera: '',
    signalFilterk: '',
    smiThreshold: ""
  }
  maintenanceSteps = {
    supplyInventory: "VERIFY SUPPLY INVENTORY",
    cleanChamber: "CLEAN TESTING CHAMBER (BLUE DOT BRUSH)",
    dryChamber: "DRY TESTING CHAMBER (FOAM BRUSH)",
    dustChamber: "DUST TESTING CHAMBER (WOODEN BRUSH)",
    passedSelfTestConfirm: "CONFIRM SYSTEM PASSES SELF-TEST",
  }
  datafind: any[];

  constructor(private _glob: GlobalService) {

  }

  public getStyle(title: string): string {
    return title == "title" ? 'mainHeadings' : 'anotherStyle'
  }

  makeTable = (Tbody: any[], columnCount: number, isMidTable: boolean, Twidths?) => {
    const tableBody = Tbody.reduce((pre, cur) => {
      if (columnCount == 4)
        return [...pre, cur.type == "innerTitle" ? [{ text: cur.leftText, colSpan: columnCount, style: 'mergecell' }, '', '', ''] : [{ text: cur.cells[0].leftText, style: this.getStyle(cur.type) }, { text: cur.cells[0].rightText, style: this.getStyle(cur.type) }, { text: cur.cells[1].leftText, ...cur.type != 'title' && { fillColor: cur.cells[1].leftText == '' ? '#CCCDCD' : '' }, style: this.getStyle(cur.type) }, { text: cur.cells[1].rightText, ...cur.type != 'title' && { fillColor: cur.cells[1].rightText == '' ? '#CCCDCD' : '' }, style: this.getStyle(cur.type) }]]
      else if (columnCount == 2)
        return [...pre, cur.type == "innerTitle" ? [{ text: cur.leftText, colSpan: columnCount, style: 'mergecell' }, ''] : [{ text: cur.cells[0].leftText, style: this.getStyle(cur.type) }, { text: cur.cells[0].rightText, style: this.getStyle(cur.type) }]]
    }, [])
    return {
      margin: [0, 10, 0, 0],
      table: {
        headerRows: 0,
        widths: Twidths,
        body: tableBody
      },

      layout: isMidTable ? 'default' : 'headerLineOnly'
    }
  }

  getHorizontal() {
    return {
      table: {
        widths: ['*'],
        heights: 1,
        body: [[""]]
      },
      layout: {
        hLineWidth: function (i, node) {
          return (i === 0) ? 0 : 0.5;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
      }
    }
  }

  public async maintenanceReport(facilityDetails: any, dataForReport: any, dataWithStatus: any[]) {
    console.log(facilityDetails, dataForReport, dataWithStatus);

    const header = await this.reportHeader(facilityDetails);


    const extractTableData = (lablesToExtract: any) => {
      console.log(lablesToExtract)
      const filteredData = dataWithStatus.filter(el => Object.keys(lablesToExtract).includes(el.parameters))
      const data = filteredData.map(el => {
        return {
          cells: [{
            leftText: titles[el.parameters],
            rightText: Number(el.value).toFixed(2)
          }, {
            leftText: lablesToExtract[el.parameters],
            rightText: el.notInRange.status != undefined ? el.notInRange.status ? 'FAIL' : 'PASS' : ''
          }], type: 'data'
        }
      })
      return data
    }

    const getMaintenanceTable = () => {
      const maintenance = facilityDetails.lastMaintenance
      let isSameDevice: boolean = maintenance.deviceSN == dataForReport.serialNumber
      return Object.keys(this.maintenanceSteps).map(key => {
        return {
          cells: [
            {
              leftText: this.maintenanceSteps[key],
              rightText: isSameDevice ? maintenance[key] ? 'COMPLETED' : 'NOT COMPLETED' : 'N.A'
            }
          ],
          type: 'data'
        }
      })

    }

    const docDefinition = {
      footer: (currentPage, pageCount, pageSize) => {
        return { text: `PRINTED FROM SQA-iO SERIAL #${dataForReport.serialNumber} AT ${formatDate(new Date(), 'HH:mm', 'en')} ON ${formatDate(new Date(), 'dd-MMM-yyyy', 'en')}`, style: 'footerstyle' }
      },
      content: [
        { text: "SQA - iO SERVICE & MAINTENANCE REPORT", style: "header" },
        {
          alignment: 'justify',
          columns: [header.leftHeader, header.rightHeader]
        },
        {
          text: "System Information".toUpperCase(), style: "sectiontitle"
        },
        this.getHorizontal(),
        {
          columns: [
            this.makeTable([{ cells: [{ leftText: 'Serial Number'.toUpperCase(), rightText: facilityDetails.deviceSN ?? 'NA' }, { leftText: 'Driver Version'.toUpperCase(), rightText: dataForReport.OBServiceVersion ?? 'NA' }], type: 'data' }, { cells: [{ leftText: 'Device Version'.toUpperCase(), rightText: dataForReport.OBVersion }, { leftText: 'Report Date / Time'.toUpperCase(), rightText: formatDate(moment(dataForReport.testDate.slice(5, 25).toUpperCase(), "DD MMM YYYY").toDate(), 'dd-MMM-yyyy', 'en') }], type: 'data' }], 4, false, [110, 110, 110, 110]),
          ]
        },
        {
          text: "Self-Test Data".toUpperCase(), style: "sectiontitle"
        },
        this.getHorizontal(),
        {
          alignment: 'justify',
          columns: [
            {
              alignment: 'justify',
              margin: [0, 0, 20, 0],
              stack: [
                this.makeTable(
                  [
                    { cells: [{ leftText: 'Parameter'.toUpperCase(), rightText: 'Result'.toUpperCase() }, { leftText: 'Units'.toUpperCase(), rightText: 'Pass/Fail'.toUpperCase() }], type: 'title' },
                    { type: 'innerTitle', leftText: 'MEASUREMENTS' },
                    ...extractTableData(this.measurementsParams)
                  ], 4, true, [100, 55, 55, 55]
                ),
                this.makeTable(
                  [
                    { type: 'innerTitle', leftText: 'CALCULATIONS' },
                    ...extractTableData(this.calculationParams)
                  ], 4, true, [100, 55, 55, 55]
                )

              ]
            },

            this.makeTable(
              [
                { cells: [{ leftText: 'Parameter'.toUpperCase(), rightText: 'Result'.toUpperCase() }], type: 'title' },
                { type: 'innerTitle', leftText: 'SETTINGS' },
                ...extractTableData(this.settingsParams)
              ], 2, true, [100, 55]
            ),
          ]
        },
        {
          text: "MAINTENANCE STEPS".toUpperCase(), style: "sectiontitle"
        },
        this.getHorizontal(),
        this.makeTable(
          [
            ...getMaintenanceTable()
          ], 2, false, [220, 220]
        ),
        {
          text: "COMMENTS".toUpperCase(), style: "sectiontitle"
        },
      ],

      styles: {
        header: {
          fontSize: 11,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        facilityHeader: {
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 8.7,
          color: 'black',
          margin: [0, 0, 0, 0]
        },
        sectiontitle: {
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 8.7,
          color: 'black',
          margin: [0, 10, 0, 0]
        },
        anotherStyle: {
          alignment: 'left',
          // fontSize: this.smallFont ? 7.5 : 8.8,
          fontSize: 8.7,
          margin: [0, 2, 0, 0],
          normal: 'Helvetica',
          color: 'black',
        },
        mergecell: {
          normal: 'Helvetica',
          fontSize: 8.7,
          color: 'black',
          italics: true,
          fillColor: "#D4EDF8"
        },
        mainHeadings: {
          normal: 'Helvetica',
          fontSize: 8.7,
          color: 'black',
          bold: 'Helvetica-Bold',
          fillColor: "#ACD4E4"
        },
        footerstyle: {
          normal: 'Helvetica',
          fontSize: 7.7,
          color: 'grey',
          alignment: 'center'
        },
      }
    };

    pdfMake.createPdf(docDefinition).open();
  }


  async reportHeader(facilityDetails) {
    var returningHeader: any = {}
    var headerArray: any = [];
    var whiteURI = 'assets/images/SQA-iO-Logo-grey.png'
    var imageObj: any = {}

    if (facilityDetails.logoURL && facilityDetails.logoURL !== "" && facilityDetails.logoURL !== undefined) {
      try {
        var logoUrl1 = await this.getBase64ImageFromURL(facilityDetails.logoURL);
      } catch {
        logoUrl1 = await this.getBase64ImageFromURL(whiteURI);
      }
    } else {
      logoUrl1 = await this.getBase64ImageFromURL(whiteURI);
    }
    var width: any = {}
    await this.limitImageDims(logoUrl1, 55).then((data: any) => {
      width = data
      // console.log("checking limit", data);
    })
    var font = "Roboto"

    imageObj = {
      image: logoUrl1,
      width: width.width,
      height: width.height,
      // style: 'address'
    }

    headerArray.push([{ text: "Phone:", style: 'anotherStyle' }, { text: facilityDetails.phone ?? 'NA', style: 'anotherStyle' }])
    headerArray.push([{ text: "Email:", style: 'anotherStyle' }, { text: facilityDetails.email ?? 'NA', style: 'anotherStyle' }])
    headerArray.push([{ text: "Website:", style: 'anotherStyle' }, { text: facilityDetails.website ?? 'NA', style: 'anotherStyle' }])
    const table = {
      table: {
        body: headerArray
      },
      layout: {
        defaultBorder: false,
      }
    }
    let address = ""
    if (facilityDetails.streetNo.split(";")[1] != undefined && facilityDetails.streetNo.split(";")[1] != "null") {
      address = facilityDetails.streetNo.split(";")[0] + ' ' + facilityDetails.streetNo.split(";")[1]
    } else {
      address = facilityDetails.streetNo.split(";")[0]
    }
    if (facilityDetails.state != undefined && facilityDetails.state != '') {
      address += ', ' + facilityDetails.city + ' ' + facilityDetails.zipCode + ', ' + facilityDetails.state
    } else {
      address += ', ' + facilityDetails.city + ' ' + facilityDetails.zipCode
    }

    const leftTable = {
      table: {
        widths: [140],
        headerRows: 1,
        body: [
          // [imageObj],
          [{ text: facilityDetails.facilityName, style: 'sectiontitle' }],
          [{ text: address, style: 'anotherStyle', margin: [0, 5, 0, 0] }]
        ]
      }, layout: {
        defaultBorder: false,
      }
    }


    returningHeader = {
      rightHeader: table,
      leftHeader: leftTable,
    }



    return returningHeader;
  }
  public limitImageDims(imgUrl, setHeight) {
    return new Promise<any>((res, rej) => {
      this.getImageDims(imgUrl).then(dimensions => {
        // console.log('returned img dims', dimensions);
        let setWidth = Math.round(setHeight * dimensions.width / dimensions.height);
        // console.log(`new dimenstions: ${setWidth} x ${setHeight}`);
        res({ width: setWidth, height: setHeight });
      }).catch(err => {
        // console.log('img dims error', err);
        rej(err);
      });
    });


  }
  getImageDims(imgUrl) {

    let img = new Image();
    return new Promise<any>((res, rej) => {
      img.src = imgUrl;
      img.onload = () => {
        let imgWidth = img.width;
        let imgHeight = img.height;
        // console.log('height: ' + imgHeight);
        // console.log('width: ' + imgWidth);
        // return [imgHeight, imgWidth];
        res({ width: imgWidth, height: imgHeight });
      };
      img.onerror = (err) => {
        rej(err);
      }
    });
  }

  getBase64ImageFromURL(url, isChart?: boolean) {
    var whiteURI = 'assets/images/SQA-iO-Logo-grey.png';
    if (isChart) {
      if (url !== null && url !== undefined && url !== '') {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");

          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, -20);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
          };

          img.onerror = error => {
            reject(error);
          };

          img.src = url;
        });
      } else {
        return whiteURI;
      }
    } else {
      if (url !== null && url !== undefined && url !== '') {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");

          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
          };

          img.onerror = error => {
            reject(error);
          };

          img.src = url;
        });
      } else {
        return whiteURI;
      }
    }
  }
  getDocumentDefinition(name: any, datain: any, arraydata: any[]) {
    let ref1: any, refv1: any, refst: any, ref2: any, refv2: any, refst1: any, led: any, ledv: any, ledst: any, led1: any, ledv1: any, ledst1: any, amp: any, ampv: any, ampst: any, z: any, zv: any, zst: any, con: any, conv: any, cost: any, smi: any, smiv: any, smist: any, msc: any, mscv: any, mscst: any, cz: any, czv: any, czst: any, odv: any, odvv: any, odvst: any, lb: any = 'N.A', lbv: any = 'N.A', lbst: any = 'N.A', datee: Date;
    let date = new Date();
    console.log(date);
    arraydata.forEach((ele, index) => {
      if (ele.parameters === "ref1") {
        console.log(ele.calibrated, ele.value);
        ref1 = ele.calibrated;
        refv1 = ele.value;
        refst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';
      }

      else if (ele.parameters === "ledCurrent1") {
        console.log(ele.calibrated);
        led = ele.calibrated;
        ledv = ele.value;
        ledst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "ref2") {
        console.log(ele.calibrated);
        ref2 = ele.calibrated;
        refv2 = ele.value;
        refst1 = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "ledCurrent2") {
        console.log(ele.calibrated);
        led1 = ele.calibrated;
        ledv1 = ele.value;
        ledst1 = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "amplitude") {
        console.log(ele.calibrated);
        amp = ele.calibrated;
        ampv = ele.value;
        ampst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "zeroLevel") {
        console.log(ele.calibrated);
        z = ele.calibrated;
        zv = ele.value;
        zst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "controlRef1") {
        console.log(ele.calibrated);
        con = ele.calibrated;
        conv = ele.value;
        cost = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "smiThreshold") {
        console.log(ele.calibrated);
        smi = ele.calibrated;
        smiv = ele.value;
        smist = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';
      }

      else if (ele.parameters === "mscAmplification") {
        console.log(ele.calibrated);
        msc = ele.calibrated;
        mscv = ele.value;
        mscst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "controlZeroLevel") {
        console.log(ele.calibrated);
        cz = ele.calibrated;
        czv = ele.value;
        czst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "od") {
        console.log(ele.calibrated);
        odv = ele.calibrated;
        odvv = ele.value;
        odvst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';

      }
      else if (ele.parameters === "LB_OD_AMP") {
        console.log(ele.calibrated);
        lb = ele.calibrated;
        lbv = ele.value;
        lbst = ele.notInRange.status !== undefined ? ele.notInRange.status === false ? 'Pass' : 'Fail' : '';
      }

    });
    let post: any, post1: any;
    if (this._glob.date.length > 0) {
      post = this._glob.date[0].testDate;
      post1 = post.split(' ', 5).join(' ');

    }
    else {
      post1 = 'N.A';
    }
    let date1 = datain.testDate.split(' ', 5).join(' ');
    console.log(date1, post1, name, datain, arraydata);

    const report = {
      footer: (currentPage, pageCount, pageSize) => {
        return { text: `FAC ID: ${name.uid}`, style: 'footerstyle' }
      },
      content: [
        {
          text: 'SQA-IO Sperm Quality Analyzer Calibration Confirmation',
          style: 'header',
          bold: 'Helvetica-Bold',
          alignment: 'center',
          margin: [0, 0, 0, 30],
          fontSize: 16,
          normal: 'Helvetica'
        },
        { text: 'SUMMARY:', bold: 'Helvetica-Bold', margin: [0, 20, 0, 0], fontsize: 12, normal: 'Helvetica' },
        this.getHorizontal(),
        {
          style: 'tableExample',
          fontSize: 9,
          margin: [0, 10, 0, 10],
          table: {
            headerRows: 1,
            widths: ['auto', 220, 'auto', 'auto'],
            body: [
              ['Instrument name:', 'SQA-IO Sperm Quality Analyzer', 'Initial Date:', post1],
              ['Serial Number:', datain.serialNumber, 'Current Date:', date1],
              ['Customer:', name.facilityName, '', ''],
            ]
          },
          layout: 'noBorders',
        },
        { text: 'CALIBRATED DATA:', bold: 'Helvetica-Bold', margin: [0, 20, 0, 0], fontsize: 12, normal: 'Helvetica' },
        this.getHorizontal(),
        {
          style: 'tableExample',
          fontSize: 10,
          margin: [65, 20, 0, 0],
          table: {
            headerRows: 1,
            body: [
              [{ text: 'PARAMETER', style: 'tableHeader', bold: 'Helvetica-Bold', fillColor: '#ACD4E4', fontSize: 10, normal: 'Helvetica' }, { text: 'INITIAL', style: 'tableHeader', bold: 'Helvetica-Bold', fillColor: '#ACD4E4', fontSize: 10, normal: 'Helvetica' }, { text: 'CURRENT', style: 'tableHeader', bold: 'Helvetica-Bold', fillColor: '#ACD4E4', fontSize: 10, normal: 'Helvetica' }, { text: 'RANGE', style: 'tableHeader', bold: 'Helvetica-Bold', fillColor: '#ACD4E4', fontSize: 10, normal: 'Helvetica' }, { text: 'PASS/FAIL', style: 'tableHeader', bold: 'Helvetica-Bold', fillColor: '#ACD4E4', fontSize: 10, normal: 'Helvetica' }],
              ['REF 1:', ref1, refv1, '150 - 350mV', refst],
              ['LED Current 1:', led, ledv, '5 - 20mA', ledst],
              ['REF 2:', ref2, refv2, '2,500mV - 3,500mV', refst1],
              ['LED Current 2:', led1, ledv1, '10 - 32 mA', ledst1],
              ['Amplitude:', amp, ampv, '50 - 100mV', ampst],
              ['Zero Level:', z, zv, '500 - 525', zst],
              ['Control Ref:', con, conv, '19', cost],
              ['SMI Thresholds:', smi, smiv, '32', smist],
              ['MSC Amplification:', msc, mscv, '100', mscst],
              ['Control ZL:', cz, czv, '131', czst],
              ['OD Value:', odv, odvv, '1.5', odvst],
              ['LB OD AMP:', lb, lbv, '990', lbst],
            ],

          },
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'black' : 'black';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 'black' : 'black';
            },

          }
        },
      ],
      styles:{
        footerstyle: {
          normal: 'Helvetica',
          fontSize: 8,
          color: 'grey',
          alignment: 'center'
        },
      }
    };
    pdfMake.createPdf(report).open();
  }

}
