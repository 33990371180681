<div class="container">
    <!----------*** ledCurrent1 ***------------>
    <div class="row" style="text-transform: capitalize;" *ngIf="this.analysis != undefined && this.analysis.ledCurrent1">
        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Device Critical</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ledCurrent1.isDeviceCritical}}</div>
            <!-- <i *ngIf="analysis.ledCurrent1.isDeviceCritical"
                class="material-icons md-18 ml-1 fail col-5">check_circle</i>
            <i *ngIf="!analysis.ledCurrent1.isDeviceCritical" class="material-icons md-18 ml-1 pass col-5">cancel</i> -->
        </div>

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Device Failed</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ledCurrent1.isDeviceFailed}}</div>
        </div>

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Fluctuating</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ledCurrent1.isFluctuating}}</div>
        </div>

        <!-- <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Trend Negative</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ledCurrent1.isTrendNegative}}</div>
        </div> -->

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Trend Score</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ledCurrent1.trendScore.toFixed(3)}}</div>
        </div>

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Trend Slope</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ledCurrent1.trendSlope.toFixed(3)}}</div>
        </div>
    </div>

    <!----------*** ref2 ***------------>
    <div class="row" style="text-transform: capitalize;" *ngIf="this.analysis != undefined && this.analysis.ref2">
        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Device Critical</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ref2.isDeviceCritical}}</div>
            <!-- <i *ngIf="analysis.ledCurrent1.isDeviceCritical"
                class="material-icons md-18 ml-1 fail col-5">check_circle</i>
            <i *ngIf="!analysis.ledCurrent1.isDeviceCritical" class="material-icons md-18 ml-1 pass col-5">cancel</i> -->
        </div>

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Device Failed</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ref2.isDeviceFailed}}</div>
        </div>

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Fluctuating</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ref2.isFluctuating}}</div>
        </div>

        <!-- <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Trend Negative</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ref2.isTrendNegative}}</div>
        </div> -->

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Trend Score</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ref2.trendScore.toFixed(3)}}</div>
        </div>

        <div class="col-md-6 col-lg-4 d-flex">
            <div class="col-6">Trend Slope</div>
            <div class="col-1">:</div>
            <div class="col-5">{{analysis.ref2.trendSlope.toFixed(3)}}</div>
        </div>
    </div>
</div>