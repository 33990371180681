import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";

//types
import { DataAnalysis, U_Statistics } from './main/main.model';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  db = firebase.default.app().firestore()
  url = "https://ioanalyticservices-ldoxyjbqhq-uc.a.run.app/";
  iogeneralURL = 'https://iogeneralservices-ldoxyjbqhq-uc.a.run.app/';
  statisticURL = 'https://iostatistic-ldoxyjbqhq-uc.a.run.app/';


  userTypeAndFacilities: { isDistributorAccess: boolean; facilities: string[] };

  constructor(private _http: HttpClient, private afs: AngularFirestore) {
  }

  public getDeviceData(sn: string) {
    return this._http.post(`${this.url}serviceData`, { "sn": sn });
  }

  public getCollectionData(collectionName: string = "System", documentName: string = "referenceValues") {

    let url = "https://iogeneralservices-ldoxyjbqhq-uc.a.run.app/getcollectiondata";

    return this._http.post(url, { "collection": collectionName, "doc": documentName });
  }


  public filterData(data) {

    const url = "https://iostatistic-ldoxyjbqhq-uc.a.run.app/u_statistics"      // Call for generalservices (nodejs server implementation)
    //const url = "http://localhost:8080/u_statistics"
    //array = {"x": x_array, "y": y_array}

    return this._http.post(url, { "array": data });


  }

  getDeviceFac(SN: string): Promise<any> {
    return new Promise((res, rej) => {
      this.getCollectionData("Devices", SN).pipe(
        map((val: any) => {

          return val.data != null ? val.data.usingFacility?.facilityId : undefined
        })
      ).subscribe(facId => {
        this.getCollectionData("SQACustomers", facId).subscribe((result: any) => {
          res(result.data)
        })
      })
    })
  }

  async checkingDistributor(email: string) {
    return new Promise((response, rej) => {
      this.db.collection("Distributers").where('email', '==', email).get().then(res => {


        if (res.docs.length != 0) {
          const result = res.docs[0]?.data();

          if (result.distributorSiteAccess === "advanced") {
            this.getMESDistributersFacilities(true, result.mesDistributer.id)
          } else {
            this.getMESDistributersFacilities(false, result.mesDistributer.id)
          }

          response(result);
        } else {
          this.db.collection("MESUsers").where('email', '==', email).where('adminType', '==', 'Admin (all permissions)').get().then(admin => {
            this.getMESDistributersFacilities(true, undefined)
            response(admin.docs[0].data())
          })
        }
      })
    })

  }

  //get data_analysis report
  getDataAnalysis(data: DataAnalysis): Promise<any> {
    return new Promise((response, reject) => {
      return this._http.post(`${this.statisticURL}data_analysis`, data).subscribe({
        next: (res) => response(res),
        error: (err) => reject(err)
      });
    })
  }

  getClearGraph(data: U_Statistics): Promise<any> {
    return new Promise((response, reject) => {
      const url = "https://iostatistic-ldoxyjbqhq-uc.a.run.app/u_statistics"      // Call for generalservices (nodejs server implementation)
      return this._http.post(url, data).subscribe({
        next: (res) => response(res),
        error: (err) => reject(err)
      });
    })

  }

  getClearGraphAndAnalysis(data1: DataAnalysis | null, data2: U_Statistics): Promise<[any, any]> {
    if (!data1) {
      return this.getClearGraph(data2).then(res => [{}, res]).catch(err => err);
    } else {
      return Promise.all([this.getDataAnalysis(data1), this.getClearGraph(data2)]);
    }
  }

  //getDeviceSelfTests
  getDeviceSelfTests(SN: string): Promise<any> {
    return new Promise((response, reject) => {
      const body = {
        deviceSN: SN,
        limit: 1
      }
      return this._http.post(`${this.iogeneralURL}getDeviceSelfTests`, body).subscribe({
        next: (res: any) => response(res?.data),
        error: (err) => reject(err)
      });
    })
  }

  //getSelfTests
  getSelfTests(id: string): Promise<any> {
    return new Promise((response, reject) => {
      const body = {
        facilityId: id,
        limit: 1
      }
      return this._http.post(`${this.iogeneralURL}getSelfTests`, body).subscribe({
        next: (res: any) => response(res?.data),
        error: (err) => reject(err)
      });
    })
  }

  //get mesDistributersFacilities data
  getMESDistributersFacilities(isDistributorSAccess: boolean, id?: string) {

    if (isDistributorSAccess) return this.userTypeAndFacilities = { isDistributorAccess: true, facilities: [] };

    this.db.collection("MESDistributers").doc(id).get()
      .then((res) => {
        this.userTypeAndFacilities = { isDistributorAccess: false, facilities: res.data().facilities };
      })
      .catch(err => {
        this.userTypeAndFacilities = { isDistributorAccess: false, facilities: [] };
        console.log("===MESDistributers:err===", err)
      });

  }

}
