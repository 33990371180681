import { Component, Input, OnInit } from '@angular/core';
import { DataAnalysisResponse } from '../main.model';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {

  @Input() analysis!: DataAnalysisResponse; // decorate the property with @Input()

  constructor() { }

  ngOnInit(): void {
  }

}
